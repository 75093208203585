import React from 'react';
import './AppBar.css';

const AppBar = () => (
  <div className="app-bar">
    <button>Start</button>
  </div>
);

export default AppBar;
