import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Tahoma', sans-serif;
    background-color: #00a2ed; /* Windows XP default background color */
    color: black;
    margin: 0;
    padding: 0;
  }

  .app-bar {
    background-color: #e7e7e7;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-bottom: 2px solid #ccc;
  }

  .app-bar button {
    background-color: #e7e7e7;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }

  .window {
    background-color: #fff;
    border: 2px solid #1c5aa6; /* Darker blue border */
    border-radius: 4px; /* Slight rounded corners */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    padding: 0; /* Ensure no padding */
    margin: 20px;
  }

  .window-header {
    background: linear-gradient(to bottom, #1c5aa6, #2962c7); /* Gradient from dark blue to slightly lighter blue */
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 4px; /* Match the rounded corners */
    border-top-right-radius: 4px; /* Match the rounded corners */
    margin: 0; /* Remove margin */
  }

  .window-content {
    padding: 10px;
  }
`;

export default GlobalStyles;
