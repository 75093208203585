import React from 'react';
import './Desktop.css';

const Desktop = ({ children }) => (
  <div className="desktop">
    {children}
  </div>
);

export default Desktop;
