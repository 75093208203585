import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #01529C; /* Background color similar to XP blue */
`;

const LoginContainer = styled.div`
  background-color: #E7E7E7;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 400px;
`;

const Header = styled.div`
  background: linear-gradient(to bottom, #009AE0, #005D9A);
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
`;

const InputContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;
`;

const Button = styled.button`
  background-color: #0078d7;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #005a9e;
  }
`;

const LoginPage = () => (
  <Wrapper>
    <LoginContainer>
      <Header>
        <Logo>Windows XP Professional x64 Edition</Logo>
      </Header>
      <InputContainer>
        <InputLabel>Username:</InputLabel>
        <Input type="text" placeholder="User name" />
        <InputLabel>Password:</InputLabel>
        <Input type="password" placeholder="Password" />
      </InputContainer>
      <ButtonContainer>
        <Button>OK</Button>
        <Button>Cancel</Button>
      </ButtonContainer>
    </LoginContainer>
  </Wrapper>
);

export default LoginPage;
