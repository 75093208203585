// src/pages/Home.js
import React from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import AppBar from '../components/AppBar/AppBar';
import Desktop from '../components/Desktop/Desktop';
import Window from '../components/Window/Window';

const Home = () => {
  return (
    <>
      <GlobalStyles />
      <AppBar />
      <Desktop>
        <Window title="About Me">
          <p>Welcome to my Windows XP themed portfolio!</p>
        </Window>
        <Window title="Projects">
          <p>Here are some of my projects...</p>
        </Window>
      </Desktop>
    </>
  );
}

export default Home;
