import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WindowWrapper = styled.div`
  background-color: #fff;
  border: 2px solid #1c5aa6; /* Darker blue border */
  border-radius: 4px; /* Slight rounded corners */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 0; /* Ensure no padding */
  margin: 20px;
`;

const WindowHeader = styled.div`
  background: linear-gradient(to bottom, #1c5aa6, #2962c7); /* Gradient from dark blue to slightly lighter blue */
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 4px; /* Match the rounded corners */
  border-top-right-radius: 4px; /* Match the rounded corners */
  margin: 0; /* Remove margin */
`;

const WindowContent = styled.div`
  padding: 10px;
`;

const Window = ({ title, children }) => (
  <WindowWrapper>
    <WindowHeader>
      <span>{title}</span>
    </WindowHeader>
    <WindowContent>
      {children}
    </WindowContent>
  </WindowWrapper>
);

Window.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Window;
